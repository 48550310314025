import AlertIcon from '@/public/svg/alert-circle.svg'

const Banner = () => (
  <div className="mt-8 w-full rounded-sm border border-none bg-colorErrorLight">
    <div className="flex w-full items-start justify-start gap-4 p-4">
      <div className="flex-none">
        <AlertIcon className="stroke-colorError" />
      </div>
      <div className="text-sm text-colorErrorDark">
        <p className="mb-0.5 font-medium">Action required</p>
        <p className="font-normal">
          As per{' '}
          <a
            href="https://x.com/0xDorianFi/status/1853424853983858930"
            rel="noopener noreferrer"
            target="_blank"
            className="underline"
          >
            announcement
          </a>
          , Dorian is set to sunset within the next 30 days. Please withdraw your liquidity during this transition.
          Thank you for your invaluable support.
        </p>
      </div>
    </div>
  </div>
)

export default Banner
